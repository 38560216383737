import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ActionPartial,
  ButtonSizePartial,
  ButtonStylePartial,
  ClassnamePartial,
  EnvironmentPartial,
  QaIdPartial,
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const ActionList = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.ActionList;
    return <Component {...props} />;
  },
});

const IconShare = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconShare;
    return <Component {...props} />;
  },
});

const IconDelete = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconDelete;
    return <Component {...props} />;
  },
});

const IconEdit = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconEdit;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../data/snippets/action-list.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Action List"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-action-list--docs"
      />
      <CodeSnippet
        scope={{
          React: React,
          ActionList: ActionList,
          IconShare: IconShare,
          IconDelete: IconDelete,
          IconEdit: IconEdit,
        }}
        code={snippet}
        platform="react"
        gitHubLink="action-list"
      />

      <Section title="Props">
        <PropList header="Visual">
          <ActionPartial
            showButtonType
            showGrouping
            showText
            showDisabled
            showIcon
            showQaid
            showOnClick
          />

          <ClassnamePartial componentName="action list" />

          <EnvironmentPartial />

          <ButtonSizePartial />

          <ButtonStylePartial />

          <PropListItem name="type" types={['string']}>
            <Text>Determines the type of button for the action list.</Text>
            <List type="unordered">
              <li>
                <code>primary</code> (default)
              </li>
              <li>
                <code>secondary</code>
              </li>
              <li>
                <code>subtle</code>
              </li>
            </List>
          </PropListItem>

          <PropListItem name="buttonLabel" types={['string']}>
            <Text>
              Optional text for the trigger button element. Overwrites{' '}
              <code>buttonIcon</code>.
            </Text>
          </PropListItem>

          <PropListItem name="buttonIcon" types={['ReactNode']}>
            <Text>
              Icon to display in the trigger button. If none is given and no{' '}
              <code>buttonLabel</code> is given, will default to{' '}
              <code>IconUiMore</code>. If <code>buttonIcon</code> is given, this
              will be ignored.
            </Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial componentName="action list" />
        </PropList>

        <AccessibilityAuditComponentResultsSection
          platform="React"
          componentName="Action List"
        />
      </Section>
    </PlatformTab>
  );
};

export default WebTab;
